// import {alertService} from "@/services/alert/alert.service";

export default {
    data: () => {
        return {
            data: {
            }
        }
    },
    async mounted() {

    },
    methods: {
        async list$() {
        },
    }

}
